import PropTypes from 'prop-types';
import VideoPlayerDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/VideoPlayerDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const VideoPlayerModule = ({ isGrid = false, ...rest }) => {
    const [css] = useStyletron();

    return (
        <section
            className={css({
                marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)',
                aspectRatio: 'var(--ratio-module-video-player)',
                width: '100%',
                maxHeight: 'calc(100vh - var(--height-header))',
                height: '100%',
            })}
        >
            <VideoPlayerDataTransformerBlock {...rest} />
        </section>
    );
};

VideoPlayerModule.propTypes = {
    isGrid: PropTypes.bool,
};

export default VideoPlayerModule;
